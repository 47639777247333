import * as React from "react";

import quotes from "./quotes";

export default function Modal(member) {
  return (
    <div className="modal">
      <div className="modal-body">{quotes[member.number]}</div>
    </div>
  );
}
