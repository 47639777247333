import PageTitle from "../components/pageTitle";
import tabTitle from "../components/tabTitle";
import Posts from "../components/posts/posts";

export default function Press() {
  tabTitle("Imprensa - Almeida Advogados");
  return (
    <main>
      <PageTitle title="Imprensa" />
      <section className="press">
        <div className="container">
          <Posts />
        </div>
      </section>
    </main>
  );
}
