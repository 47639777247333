import * as React from "react";
import { BsInstagram, BsWhatsapp } from "react-icons/bs";

export const Menu = ({ isMenuOpen }) => {
  return (
    <div className={`menu ${isMenuOpen ? "menu-open" : ""}`}>
      <ul>
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/sobre">Sobre</a>
        </li>
        <li>
          <a href="/imprensa">Imprensa</a>
        </li>
        <li>
          <a href="/contato">Contato</a>
        </li>
        <div className="social-icons">
          <li>
            <a
              className="icon"
              href="http://instagram.com/almeidaadvs"
              target="_blank"
              rel="noreferrer"
            >
              <BsInstagram />
            </a>
          </li>
          <li>
            <a
              className="icon"
              href="https://api.whatsapp.com/send?phone=558330634743"
              target="_blank"
              rel="noreferrer"
            >
              <BsWhatsapp />
            </a>
          </li>
        </div>
      </ul>
    </div>
  );
};
