import { BsInstagram, BsWhatsapp } from "react-icons/bs";
import { HiMail } from "react-icons/hi";
import "../styles/footer.scss";

export default function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="main-footer">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15832.29937071049!2d-35.885259!3d-7.2323042!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8712bcd201572832!2sCentro%20Jur%C3%ADdico%20Ronaldo%20Cunha%20Lima!5e0!3m2!1spt-BR!2sbr!4v1656356438494!5m2!1spt-BR!2sbr"
        title="Endereço"
      />

      <section className="copyright">
        <section className="container">
          <div>© {year} - Almeida Advogados.</div>
          <div className="footer-contact">
            <p>
              <HiMail />
              contato@almeidaadvs.com.br
            </p>
            <p>
              <BsWhatsapp />
              (83) 3063-4743
            </p>
            <p>
              <BsInstagram />
              almeidaadvs
            </p>
          </div>
        </section>
      </section>
    </footer>
  );
}
