import Navbar from "./components/navbar";
import AppRoutes from "./routes";
import Footer from "./components/footer";

import "./styles/index.scss";

export default function App() {
  return (
    <>
      <Navbar />
      <AppRoutes />
      <Footer />
    </>
  );
}
