import { useState, useCallback } from "react";
import { ReactDimmer } from "react-dimmer";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "../components/gallery";

import ModalQuote from "../components/modal";

import PageTitle from "../components/pageTitle";
import tabTitle from "../components/tabTitle";

import member01 from "../images/member-01.png";
import member02 from "../images/member-02.png";
import member03 from "../images/member-03.png";
import member04 from "../images/member-04.png";
import member05 from "../images/member-05.png";

import quoteBalloon from "../images/quote-balloon.svg";

import { BsBriefcaseFill } from "react-icons/bs";
import { BsPeopleFill } from "react-icons/bs";
import { IoConstruct } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { GiHealthNormal } from "react-icons/gi";
import { GiHandcuffs } from "react-icons/gi";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { RiBuilding2Fill } from "react-icons/ri";
import { FaBalanceScale } from "react-icons/fa";
import { FaTags } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { MdFamilyRestroom } from "react-icons/md";

import "../styles/page.scss";

export default function About() {
  tabTitle("Sobre - Almeida Advogados");

  const [isModalOpen, setModal] = useState(false);

  const [member, setMember] = useState("");

  function handleClick(event) {
    setModal((prevState) => !prevState);
    console.log(event.target);
    setMember(event.target.id);
  }

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <main>
      <PageTitle title="Sobre" />
      <section className="about">
        <section className="office container">
          <h1>O Escritório</h1>
          <div className="flex">
            <div className="about-image" />
            <div className="about-text">
              <p>
                Em atuação desde o ano de 2009, sempre pautado na ética e
                eficiência, o Escritório Almeida Advogados se propõe a empregar
                junto aos seus clientes e parceiros uma metodologia de trabalho
                consultiva e de assessoria permanente, buscando solucionar as
                demandas que lhe são postas de maneira célere e efetiva.
              </p>
              <p>
                O quadro societário do Escritório Almeida Advogados traz a fusão
                perfeita da experiência, prática jurídica e maturidade, com o
                furor, inquietação e inovação, contando com adequada estrutura
                física e modernos equipamentos para desenvolvimento e
                acompanhamento processual na Paraíba e nos estados vizinhos.
              </p>
              <p>
                O Escritório Almeida Advogados atua nas mais diversas áreas do
                Direito, com enfoque especial ao atendimento à Pessoas Jurídicas
                e suas necessidades consectárias, assim, embasado em
                planejamentos específicos para a atividade desempenhada e
                primando pelo trabalho consultivo efetivado de maneira pontual,
                busca a maximização dos lucros e a diminuição dos passivos
                inesperados, reavendo ativos obstaculizados e conferindo
                qualidade aos serviços ofertados.
              </p>
            </div>
          </div>
          <div className="gallery">
            <Gallery photos={photos} onClick={openLightbox} />
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <Carousel
                    currentIndex={currentImage}
                    views={photos.map((x) => ({
                      ...x,
                      srcset: x.srcSet,
                      caption: x.title,
                    }))}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </div>
        </section>

        <section className="areas">
          <div className="container">
            <h1>Áreas de Atuação</h1>
            <ul>
              <li className="area">
                <BsBriefcaseFill />
                <h2>Direito Empresarial e Corporativo</h2>
              </li>
              <li className="area">
                <IoConstruct />
                <h2>Direito do Trabalho</h2>
              </li>
              <li className="area">
                <RiMoneyDollarCircleFill />
                <h2>Direito Tributário</h2>
              </li>
              <li className="area">
                <RiBuilding2Fill />
                <h2>Direito Imobiliário</h2>
              </li>
              <li className="area">
                <BsPeopleFill />
                <h2>Direito Administrativo</h2>
              </li>
              <li className="area">
                <GiHealthNormal />
                <h2>Direito em Saúde Suplementar</h2>
              </li>
              <li className="area">
                <RiMoneyDollarCircleFill />
                <h2>Direito Previdenciário</h2>
              </li>
              <li className="area">
                <FaShoppingCart />
                <h2>Direito Marcário</h2>
              </li>
              <li className="area">
                <FaTags />
                <h2>Direito do Consumidor</h2>
              </li>
              <li className="area">
                <FaBalanceScale />
                <h2>Direito Constitucional</h2>
              </li>
              <li className="area">
                <GiHandcuffs />
                <h2>Direito Criminal</h2>
              </li>
              <li className="area">
                <MdFamilyRestroom />
                <h2>Direito Familia e Sucessões</h2>
              </li>
              <li className="area">
                <IoDocumentText />
                <h2>Direito em Licitações e Contratos</h2>
              </li>
            </ul>
          </div>
        </section>

        <section className="members">
          <div className="container">
            <h1>Quem somos</h1>
            <ul>
              <li>
                <img
                  className="avatar"
                  src={member02}
                  alt="Foto de Fábio Almeida"
                />
                <div className="quote-icon">
                  <button onClick={handleClick}>
                    <img
                      className="quote-icon"
                      src={quoteBalloon}
                      id="1"
                      alt=""
                    />
                  </button>
                </div>
                <h2>Fábio Almeida</h2>
                <h3>OAB/PB 14.755</h3>
                <p>
                  Advogado. Palestrante. Formado em Ciências Jurídicas e Sociais
                  pela UFCG/UnB. Especialista em Direito Tributário e Processo
                  Tributário (ESA-PB/UNIFACISA). Pós-graduando em Direito Civil
                  e Processo Civil (ESA-PB).
                </p>
              </li>
              <li>
                <img
                  className="avatar"
                  src={member04}
                  alt="Foto de Julio Sarmento"
                />
                <div className="quote-icon">
                  <button onClick={handleClick}>
                    <img
                      className="quote-icon"
                      src={quoteBalloon}
                      id="3"
                      alt=""
                    />
                  </button>
                </div>
                <h2>Julio Sarmento</h2>
                <h3>OAB/PB 14.668</h3>
                <p>
                  Advogado. Palestrante. Formado em Ciências Jurídicas e Sociais
                  pela UFCG. Especialista em Direito e Gestão pelo Instituto de
                  Direito Público Brasiliense - IDP.
                </p>
              </li>
              <li>
                <img
                  className="avatar"
                  src={member01}
                  alt="Foto de Edjarde Arcoverde"
                />
                <div className="quote-icon">
                  <button onClick={handleClick}>
                    <img
                      className="quote-icon"
                      src={quoteBalloon}
                      id="0"
                      alt=""
                    />
                  </button>
                </div>
                <h2>Edjarde Arcoverde</h2>
                <h3>OAB/PB 16.198</h3>
                <p>
                  Advogado. Palestrante. Formado em Ciências Jurídicas e Sociais
                  pela UFPB. Especialista em Direito Material e Processual do
                  Trabalho (ESMAT 13).
                </p>
              </li>
              <li>
                <img
                  className="avatar"
                  src={member03}
                  alt="Foto de Flávio Almeida"
                />
                <div className="quote-icon">
                  <button onClick={handleClick}>
                    <img
                      className="quote-icon"
                      src={quoteBalloon}
                      id="2"
                      alt=""
                    />
                  </button>
                </div>
                <h2>Flávio Almeida</h2>
                <h3>OAB/PB 24.999</h3>
                <p>
                  Advogado. Formado em Direito pela UNIFACISA. Graduando em
                  Ciências Contábeis (UNINASSAU) Especialista em Direito
                  Material e Processual do Trabalho (Estácio de Sá/RJ).
                </p>
              </li>
              <li>
                <img
                  className="avatar"
                  src={member05}
                  alt="Foto de Letícia Dantas"
                />
                <div className="quote-icon">
                  <button onClick={handleClick}>
                    <img
                      className="quote-icon"
                      src={quoteBalloon}
                      id="4"
                      alt=""
                    />
                  </button>
                </div>
                <h2>Letícia Dantas</h2>
                <h3>OAB/PB 30.203</h3>
                <p>Advogada. Formada em Direito pela UNIFACISA.</p>
              </li>
            </ul>
          </div>
        </section>
      </section>
      {isModalOpen && <ModalQuote number={member} closeModal={setModal} />}
      <ReactDimmer
        isOpen={isModalOpen}
        exitDimmer={setModal}
        zIndex={100}
        blur={1.5}
      />
    </main>
  );
}
