import { Query } from "react-apollo";
import gql from "graphql-tag";

const FeaturedPosts = () => (
  <Query
    query={gql`
      {
        posts {
          edges {
            node {
              featuredImage {
                node {
                  sourceUrl
                }
              }
              id
              title
              excerpt
            }
          }
        }
      }
    `}
  >
    {({ loading, error, data }) => {
      if (loading) {
        return (
          <div className="content">
            <h2>Carregando...</h2>
          </div>
        );
      }
      return (
        <div className="articles">
          {data.posts.edges.slice(0, 3).map((post, key) => {
            return (
              <article key={key}>
                <img
                  src={post.node.featuredImage.node.sourceUrl}
                  alt={post.node.title}
                />
                <div className="post-text">
                  <h2>{post.node.title}</h2>
                  <p
                    dangerouslySetInnerHTML={{ __html: post.node.excerpt }}
                  ></p>
                </div>
              </article>
            );
          })}
        </div>
      );
    }}
  </Query>
);

export default FeaturedPosts;
