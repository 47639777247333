import React, { useEffect, useRef, useState } from "react";
import emailjs from "emailjs-com";
import { FaCheck } from "react-icons/fa";

export default function ContactForm() {
  const form = useRef();

  const [status, setStatus] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_2qtjc6f",
        "template_viua4fo",
        form.current,
        "ehqu_S93BUeCGqsjf"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setStatus("SUCCESS");
    e.target.reset();
  };

  useEffect(() => {
    if (status === "SUCCESS") {
      setTimeout(() => {
        setStatus("");
      }, 5000);
    }
  }, [status]);

  const renderAlert = () => (
    <div className="render-alert">
      <p>
        <FaCheck />
        Mensagem enviada com sucesso!
      </p>
    </div>
  );

  return (
    <>
      <p>Fale conosco através do nosso formulário de contato.</p>
      {status && renderAlert()}
      <form ref={form} onSubmit={sendEmail}>
        <input type="text" name="user_name" placeholder="Nome" />
        <input type="email" name="user_email" placeholder="E-mail" required />
        <input type="tel" name="user_phone" placeholder="Telefone" />
        <input type="text" name="subject" placeholder="Assunto" />
        <textarea name="message" placeholder="Mensagem" />
        <button type="submit">Enviar →</button>
      </form>
    </>
  );
}
