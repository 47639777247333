import ContactForm from "../components/contactForm";
import PageTitle from "../components/pageTitle";
import tabTitle from "../components/tabTitle";

export default function Contact() {
  tabTitle("Contato - Almeida Advogados");
  return (
    <main>
      <PageTitle title="Contato" />
      <section className="contact">
        <div className="container">
          <div className="contact-img" />
          <div className="contact-text">
            <ContactForm />
          </div>
        </div>
      </section>
    </main>
  );
}
