export const photos = [
  {
    src: require("../images/gallery/gallery-01.jpg"),
    width: 1,
    height: 1,
  },
  {
    src: require("../images/gallery/gallery-02.jpg"),
    width: 1,
    height: 1,
  },
  {
    src: require("../images/gallery/gallery-03.jpg"),
    width: 1,
    height: 1,
  },
  {
    src: require("../images/gallery/gallery-04.jpg"),
    width: 1,
    height: 1,
  },
  {
    src: require("../images/gallery/gallery-05.jpg"),
    width: 1,
    height: 1,
  },
  {
    src: require("../images/gallery/gallery-06.jpg"),
    width: 1,
    height: 1,
  },
  {
    src: require("../images/gallery/gallery-07.jpg"),
    width: 1,
    height: 1,
  },
  {
    src: require("../images/gallery/gallery-08.jpg"),
    width: 1,
    height: 1,
  },
  {
    src: require("../images/gallery/gallery-09.jpg"),
    width: 1,
    height: 1,
  },
  {
    src: require("../images/gallery/gallery-10.jpg"),
    width: 1,
    height: 1,
  },
];
