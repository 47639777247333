import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/home";
import About from "../pages/about";
import Press from "../pages/press";
import Contact from "../pages/contact";
import Posts from "../components/posts/posts";
import Post from "../components/posts/featuredPosts";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";

const client = new ApolloClient({
  uri: "https://almeidaadvs.com.br/wordpress/graphql",
});

export default function AppRoutes() {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sobre" element={<About />} />
          <Route path="/imprensa" element={<Press />} />
          <Route path="/contato" element={<Contact />} />
          <Route path="/posts" element={<Posts />} />
          <Route path="/posts/:slug" element={<Post />} />
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
}
