import tabTitle from "../components/tabTitle";
import { BsBriefcaseFill } from "react-icons/bs";
import { IoConstruct } from "react-icons/io5";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import FeaturedPosts from "../components/posts/featuredPosts";
import "../styles/page.scss";

export default function Home() {
  const date = new Date();
  const year = date.getFullYear();
  const yearsOfAttuation = year - 2009;
  tabTitle("Home - Almeida Advogados");
  return (
    <main>
      <section className="main-hero" />
      <div className="hero-text container">
        <div className="text-wrap">
          <div className="text-box">
            <h1>Ética e eficiência</h1>
            <p>Assessoria consultiva e permanente</p>
          </div>
        </div>
        <div className="text-wrap">
          <div className="text-box">
            <h1>Experiência</h1>
            <p>Mais de {yearsOfAttuation} anos de atuação</p>
          </div>
        </div>
        <div className="text-wrap">
          <div className="text-box">
            <h1>Inovação</h1>
            <p>Estrutura moderna e adequada</p>
          </div>
        </div>
      </div>
      <div className="text container">
        <p>
          Em atuação desde o ano de 2009, sempre pautado na ética e eficiência,
          o escritório Almeida Advogados se propõe a empregar junto aos seus
          clientes e parceiros uma metodologia de trabalho consultiva e de
          assessoria permanente, buscando solucionar as demandas que lhe são
          postas de maneira célere e efetiva.
        </p>
      </div>

      <section className="main-areas">
        <div className="container">
          <div className="area">
            <BsBriefcaseFill />
            <h2>Direito Empresarial e Corporativo</h2>
          </div>
          <div className="area">
            <IoConstruct />
            <h2>Direito do Trabalho</h2>
          </div>
          <div className="area">
            <RiMoneyDollarCircleFill />
            <h2>Direito Tributário</h2>
          </div>
        </div>
        <a href="/sobre">
          <button type="button">Ver todas as áreas de atuação →</button>
        </a>
      </section>

      <section className="main-press">
        <div className="container">
          <FeaturedPosts />
          <a href="/imprensa">
            <button type="button">Ir para Imprensa →</button>
          </a>
        </div>
      </section>

      <section className="main-contact">
        <div className="container">
          <p>
            Quer saber mais? Fale conosco através do nosso formulário de
            contato.
          </p>
          <a href="/contato">
            <button type="button">Clique aqui →</button>
          </a>
        </div>
      </section>
    </main>
  );
}
