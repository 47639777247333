const quotes = [
  <p>
    "Após finalizar o curso de direito pela Universidade Federal da Paraíba, no
    início do ano de 2010, já sabia que o exercício da Advocacia seria meu
    futuro.
    <br />
    <br />
    Por já me encontrar inserido em um grande escritório de João Pessoa, caí de
    paraquedas no mundo da advocacia empresarial, assim iniciando a caminhada
    pelos corredores dos fóruns paraibanos. O passo seguinte foi buscar
    qualificação, tendo cursado pós-graduação em Direito Processual e Material
    do Trabalho pela ESMAT-PB.
    <br />
    <br />
    Continuando a trajetória com a imersão no Direito Empresarial e Trabalhista,
    ainda jovem, assumi a missão de coordenar o Setor Jurídico local de uma
    multinacional, fabricante de artigos esportivos,me dedicando àquela
    atividade entre os anos de 2012 e 2014, porém, nunca deixando de militar na
    advocacia.
    <br />
    <br />
    Ocorre que, como a maioria dos grandes eventos que ocorrem na vida, em uma
    conversa informal com Fábio, amigo de lingas datas e sócio-fundador do
    Almeida - Advogados, recebi o convite para me juntar ao time, estando, desde
    2014, dedicado de corpo e alma ao projeto de uma advocacia qualificada e
    focada na parceria com seus clientes.
    <br />
    <br />
    Sou Edjarde Arcoverde e sou Almeida - Advogados!"
  </p>,
  <p>
    “O ano era 2009 e uma infinidade de ideias passava em minha mente a cada
    segundo, sobretudo em razão da recente formatura e da aprovação na OAB.
    <br />
    <br />
    Assim, consciente da carreira que buscava trilhar, questionava-me sobre a
    essência de um escritório de advocacia, a necessidade de oferecer um serviço
    diferente e, claro, a busca pelo reconhecimento do trabalho a ser realizado.
    <br />
    <br />
    Neste universo, percebi que as referências locais possuíam um espectro de
    atuação muito abrangente, onde o mesmo escritório atuava com direito penal,
    previdenciário, imobiliário e bancário, de sorte que as demandas eram
    abordadas de maneira genérica e o Advogado pouco conhecia da realidade
    daquele que buscava lhe contratar.
    <br />
    <br />
    Neste momento resolvi que algo diferente precisava ser feito. Decidi então
    fundar o Escritório Almeida – Advogados, o qual teria como público alvo às
    Empresas. O objetivo seria prestar um serviço de assessoria jurídica sob um
    enfoque muito especializado, partindo da imersão no funcionamento da empresa
    e passando pela compreensão da cadeia de produção, da prestação do serviço,
    da comercialização do produto e chegando até o consumidor final. Acreditava
    que apenas desta forma seria possível identificar as questões que realmente
    precisavam ser corrigidas, aperfeiçoadas ou implementadas, antecipando-se às
    demandas.
    <br />
    <br />
    Pois bem, assim foi feito e os anos se passaram, o trabalho foi realizado,
    os planos se concretizaram e o escritório segue aumentando...
    <br />
    <br />
    Costumo dizer que atualmente contamos com uma fusão quase perfeita
    (considerando que a perfeição não existe) de experiência e maturidade com
    furor, inquietação e inovação, o que se materializa numa equipe em constante
    evolução, sempre pronta para atender as necessidades dos nossos clientes,
    mas jamais satisfeita em fazer ‘apenas isso’, consciente de que deve ir
    além, de que a sua missão não se encerra com a solução apresentada.
    <br />
    <br />É isso. Esse é o nosso Almeida – Advogados.”
  </p>,
  <p>
    Minha caminhada na área do Direito iniciou no ano de 2012 quando ingressei
    na Unifacisa – Centro Universitário. Durante a graduação tive a oportunidade
    de conhecer as mais diversas áreas e ter a certeza que a advocacia faria
    parte do meu futuro profissional.
    <br />
    <br />
    Em 2015, através de um convite realizado pelo Advogado Edjarde Arcoverde,
    tive a oportunidade de conviver, crescer e aprender durante meu estágio na
    Almeida Advogados. Foi uma das melhores experiências da minha vida. Ao
    terminar a graduação em 2017, tornei-me oficialmente parte desta equipe.
    <br />
    <br />A fim de aprimorar meus conhecimentos e melhor servir os nossos
    clientes, responsáveis pelo nosso interesse em evoluir, fiz pós-graduação em
    Direito e Processo do Trabalho, na Faculdade Estácio de Sá. Ainda,
    atualmente, estou realizando mais uma graduação, dessa vez em Ciências
    Contábeis, Uninassau, uma vez que identifiquei uma relação entre essas duas
    áreas.
    <br />
    <br />
    Por fim, minha maior satisfação é desempenhar minhas atividades
    profissionais ao passo que me realizo e contribuo com a resolução das
    necessidades dos clientes!"
  </p>,
  <p>
    "Movido por um sentimento de indescritível satisfação, apresento-lhes um
    breve resgate histórico sobre a minha trajetória educacional até chegar à
    Faculdade de Ciências Jurídicas e Sociais do Campus da Universidade Federal
    de Campina Grande sediada no município de Sousa – Paraíba.
    <br />
    <br />A caminhada não foi nada fácil, foram muitos os desafios e as
    dificuldades enfrentadas, que exigiu inesgotável esforço para frequentar os
    bancos acadêmicos de 02 Universidades, principalmente para um jovem forjado
    na escola pública, que desde a tenra idade, ansiava frequentar e conquistar
    um dos maiores sonhos de sua vida, tornar-se um advogado e lutar de forma
    intransigente na defesa dos seus ideais para servir com dignidade à Justiça.
    Assim, imbuído deste propósito, pude concluir o Curso de Direito naquela
    Universidade Federal no ano de 2004. Começava ali a minha jornada
    profissional e os novos desafios, dentre eles, o de conquistar um espaço na
    advocacia neste universo jurídico de competitividade cada vez mais
    constante.
    <br />
    <br />
    Eis que, em julho de 2010, após aprovação em processo seletivo, deixei a
    cidade de Sousa para assumir o cargo de advogado no Sistema Indústria da
    Paraíba, no município de Campina Grande, onde atualmente ocupo o cargo de
    Gerente Jurídico desta entidade.
    <br />
    <br />
    Naquele mesmo ano, convidado por um jovem promissor e visionário da
    advocacia, o Dr. Fábio Almeida, que comungava dos mesmos princípios e ideais
    de Justiça, aceitei o desafio de ingressar e fazer parte do Escritório –
    ALMEIDA ADVOGADOS, que naquela época já se notabilizava como um dos maiores
    e melhores Escritório Especializados em Direito Empresarial do Estado.
    <br />
    <br />
    Em 2011, já com maturidade profissional em alta e com maior experiência na
    área jurídica, iniciei o curso de Pós-Graduação em Direito e Gestão pelo
    Instituto de Direito Público Brasiliense – IDP, vindo, pois, a concluir em
    2013.Dentro desta perspectiva, continuarei seguindo fielmente ao direito e
    aos propósitos indeclináveis da ética e do comprometimento profissional para
    o atingimento da Justiça, tendo a vitória como grande aliada para busca de
    novas conquistas.
    <br />
    <br />
    Por fim, é neste desiderato ideólogo para satisfação de nossos clientes, que
    aceno para um horizonte de esperança para o futuro, cônscio de que nesta
    caminhada com os meus pares, tenho a certeza de que jamais estarei sozinho.
    <br />
    <br />
    Sou Julio Sarmento, advogado e integrante do Escritório – ALMEIDA
    ADVOGADOS."
  </p>,
  <p>
    "Desde que consigo me lembrar, sempre vi o Direito como o caminho que queria
    seguir, e como uma criança muito sonhadora que era, achava que essa era a
    forma que eu poderia mudar um pouco o mundo.
    <br />
    <br />
    Quando entrei no curso, me deparei com algumas paixões e muitos desafios.
    Logo no primeiro ano percebi que era uma amante do Direito Penal, e que todo
    o resto pouco me animava.
    <br />
    <br />
    Passei um ano estagiando no Ministério Público lidando com matérias
    criminais e, apesar de amar o assunto, soube desde então que eu queria mesmo
    era ir para a Advocacia.
    <br />
    <br />
    Ao começar a estagiar no Almeida – Advogados, me distanciei do crime e
    passei a lidar com todas as outras áreas que na faculdade eu não tinha muita
    intimidade. Com essa prática veio o entusiasmo pelo Direito Civil e (quase)
    todos os seus ramos.
    <br />
    <br />
    Hoje, como Advogada, encontro-me cada dia mais encantada com a profissão, e
    vejo que o sonho de criança de fazer a diferença vem se realizando a cada
    conquista junto aos meus clientes.
    <br />
    <br />
    Sou Letícia Dantas e sou Almeida – Advogados."
  </p>,
];

export default quotes;
